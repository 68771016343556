/*---------------------------- smallest >= 0 ----------------------*/
.ImageGalleryContainer {
    position: relative;
    max-width: 1460px;
    max-height: 728px;

    /* background-color: red; */
    /* max-width: 1280px; */
}

.image-gallery-image {
    border-radius: 5px;
    /* background-color: blue; */
    object-fit: cover !important;
    /* ratio w/h 0.5625 , max 1280x720 */
}

.image-gallery-thumbnails {
    /* background-color: blue; */
}

.image-gallery-thumbnails-container{
    align-items: center;
    align-content: center;
    text-align: center;
    /* background-color: red; */
}

.image-gallery-slide-wrapper{
    max-width: 1280px;
    max-height: 720px;
    /* width: calc(100% - ((min(75vw, 1485px) - 64px) / 6) - 8px) !important; */
    /* padding-left: calc( ((min(70vh, 720px) - 64px) / 56) ); */
    /* background-color: yellow; */
}

.image-gallery-thumbnail {
    border: none;
    filter: brightness(50%);
    padding: 0px;
    min-width: 32px;
    min-height: 32px;
    max-width: 144px;
    max-height: 144px;
    border-radius: calc(max(0.5vw, 5px));

    /* background-color: blue !important; */
}

.image-gallery-thumbnail.active {
    border: none;
    background-image: linear-gradient(#FFD05C, #A0553D);
    filter: brightness(100%);
}

.image-gallery-thumbnail:hover {
    border: none;
    filter: brightness(100%);
}


.image-gallery-thumbnail-inner {
    position: relative;
    padding: 0px;
    width: 100%;
    height: 100%;
    /* padding: calc(max(0.3vh, 2px));  */
    /* background-color: green; */
}

.image-gallery-thumbnail-image {
    object-fit: cover !important;
    margin: 0px !important;
    
    height: 100%;
    width: 100%;
    /* background-color: red !important;  */
}

/* //image-gallery-thumbnails-left */
/* image-gallery-thumbnails-bottom */



/*--------------------------------*/
@media (orientation: portrait) {
    .image-gallery-left-nav {
        position: absolute;
        /*image height + thumnail padding top height + thumnail / 2*/
        top: calc((min(100vw, 1280px) - 32px - 32px) * 0.5625 
            + ((min(100vw, 1280px) - 32px - 32px) /6) 
            + ((min(100vw, 1280px)  - 32px - 32px) / 6.5)/2);
        width: calc(min(100vw, 1280px)/20) !important;
        object-fit: contain;
        left: 0px;
        z-index: 99;
    }
    
    .image-gallery-right-nav {
        position: absolute;
        /*---- 100% + pading top + thumbnail/2 ---*/
        top: calc((min(100vw, 1280px) - 32px - 32px) * 0.5625 
            + ((min(100vw, 1280px) - 32px - 32px) /6) 
            + ((min(100vw, 1280px)  - 32px - 32px) / 6.5)/2);
        width: calc(min(100vw, 1280px)/20) !important;
        object-fit: contain;
        right: 0px;
        z-index: 99;
        /* background-color: red; */
    }

    .ImageGalleryContainer {
        position: relative;
        margin-left: 36px;
        margin-right: 36px;
        padding: 16px;
        width: calc(100vw - 32px - 32px);   
        height: calc(((min(100vw, 1280px) - 32px - 32px) * 0.5625) + 31vw);
        max-height: calc(((min(100vw, 1280px) - 32px - 32px) * 0.5625) + 31vw);
        /* background-color: yellow; */
    }
    
    .image-gallery-image {
        border-radius: 5px;
        /* ratio w/h 0.5625 , max 1280x720 */
        width: 100%;
        height: calc((min(100vw, 1280px) - 32px - 32px) * 0.5625);
        /* background-color: black; */
    }
    
    .image-gallery-thumbnails-wrapper{
        margin-left: 6vw !important;
        margin-right: 6vw !important;
        /* background-color: green; */
    }
    
    .image-gallery-thumbnails {
        padding-top: 12.5vw !important; 
        /* background-color: blue; */
    }

    .image-gallery-thumbnail {
        width: 12.5vw !important;
        height: 12.5vw !important;
        padding: calc(max(0.2vw,2px));
        border-radius: calc(max(0.5vw, 5px));

        &.active {
            box-shadow: 0px 0px calc(min(100vw, 1280px)/240) #FFD05C;
        }
    }

    .image-gallery-thumbnail-image {
        border-radius: calc(max(0.5vw, 5px));
    }
}

/*--------------------------------*/
@media (orientation: landscape) {
    
    .image-gallery-up-nav {
        position: absolute;
        width: 4.5vh;
        object-fit: contain;
        /* -1 * thumbnail margin + thumbnail weidth/2 */
        /* left: calc(-1 *(  ((min(75vw, 1485px)) / 40)
            + max(2.25vh, 32px)  )); */
        left: calc( 2.25vh 
                    - 4.5vh
                    - max(5vh, 32px)
                );
        z-index: 99;
    }

    .image-gallery-down-nav {
        position: absolute;
        width: 4.5vh;
        object-fit: contain;
        /* -1 * thumbnail margin + thumbnail weidth/2 */
        left: calc( 2.25vh 
                    - 4.5vh
                    - max(4.5vh, 32px)
                );
        bottom: 0px;
        z-index: 99;
    }

    .ImageGalleryContainer {
        width: 75vw !important;

        /* background-color: red; */
    }

    .image-gallery{
        /* width: calc(min(90vw, 1485px)) !important; */
        /* width: calc(min(85vw, 1280px + 304px)); */
    }

    .image-gallery-content{
        position: relative !important;
        height: calc(min(70vh, 720px)) !important;
        /* overflow: hidden; */
        /* background-color: black; */
    }
    
    .image-gallery-slide-wrapper{
        position: relative !important;
        height: 100%;
        width: calc(100% - 10vh - 15px) !important;
        /* width: 25px !important; */
        /* width: calc(100% - ((min(75vw, 1485px) - 64px) / 6) - 8px) !important; */
        /* padding-left: calc( ((min(70vh, 720px) - 64px) / 56) ); */
        /* background-color: yellow; */
    }
    .image-gallery-swipe{
        /* position: relative !important; */
        height: 100%;
    }
    .image-gallery-slides{
        /* position: relative !important; */
        height: 100%;
    }
    .image-gallery-slide{
        /* position: relative !important; */
        height: 100%;
    }
    .image-gallery-image {
        border-radius: 5px;
        /* ratio w/h 0.5625 , max 1280x720 */
        height: 100%;
        padding-right: 2px;
        padding-left: 4vh !important;
        /* background-color: red; */
        
    }

    .image-gallery-thumbnails-wrapper{
        position: relative !important;
        height: 100%;
        padding-left: 0px !important;
        width: calc(10vh + 5px) !important;
        /* margin-right: 4.5vh !important; */
        
        /* width: calc( 75vw - min(70vh, 720px) * 1.77 ) !important; */
        /* padding-top: 10%; */
        /* height: calc(100% - calc(min(70vh, 720px)/10));
        width: calc((min(70vh, 720px) - 64px) / 6) !important;
        margin: calc(min(70vh, 720px)/240) !important;
        margin-top: calc(min(70vh, 720px)/20) !important;
        margin-bottom: calc(min(70vh, 720px)/20) !important; */
        
        /* background-color: green; */
    }

    .image-gallery-thumbnails {
        position: relative !important;
        height: 90% !important;
        top: 5% !important;
        /* margin-top: 50%; */
        /* margin-top: calc(min(70vh, 720px)/30); */
        /* margin-left: 1px; */
        /* background-color: blue; */
    }

    .image-gallery-thumbnails-container{
        position: relative !important;
        
        height: 100% !important;
        /* width: 100% !important; */
        padding-left: 2px;

        /* background-color: wheat; */
    }
    

    .image-gallery-thumbnail {
        position: relative !important;


        /* width: calc((min(90vh, 720px) - 64px) / 8.2);
        height: calc((min(90vh, 720px) - 64px) / 8.2);
        
        margin-top: calc(min(90vh, 720px)/56);
        margin-bottom: calc(min(90vh, 720px)/56);
        border-radius: calc(min(90vh, 720px)/100); */
        width: 10vh !important;
        height: 10vh !important;
        padding: calc(max(0.2vw,2px)) !important;
        margin-top: calc( (min(70vh, 720px) * 0.9 - 50vh - 1px)/10 ) !important;
        margin-bottom: calc( (min(70vh, 720px) * 0.9 - 50vh - 1px)/5 ) !important;
        &.active {
            box-shadow: 0px 0px calc(min(100vh, 720px)/100) #FFD05C;
        }
        /* background-color: black; */
    }

    .image-gallery-thumbnail-inner{
        position: relative !important;
        /* padding: calc(max(0.3vh, 2px));         */
        /* background-color: yellow; */
        
    }

    .image-gallery-thumbnail-image {
        position: relative !important;
        /* width: 1px !important; */
        /* height: 1px !important; */
        margin: 0px !important;
        padding: 0px !important;
        border-radius: calc(max(1vh, 5px));
    }
}
