body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hideattop {
  visibility: hidden !important;
  opacity: 0;
}

.showattop {
  visibility: visible !important;
}

div.img-fixedbg {
  z-index: -99;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100vw;

  position: fixed;
  background-attachment: initial;
  
}

div.header-gmil {
  background-color: #242223;
    height: 32px;
    /* height: 18vh;
    max-height: 64px; */
}

div.content_over {
  position: absolute;
  overflow: hidden;
}


span.white {
  color: #FFFFFF;
}

span.brown46 {
  color: #462513;
}


/*-------------------- H -------------------------*/

span.h1 {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 700;
  font-size: calc(min(8vh, 90px));
  /* line-height: 5.5vw; */
}

span.h2 {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-size: 3.3vh;
  /* line-height: 5vw; */
}

span.h3 {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-size: .93vw;
  /* line-height: 2vw; */
}


@media (min-width: 767.98px) and (orientation: portrait) {
  span.h1 {
      /* font-family: 'Kanit'; */
      /* font-style: normal; */
      /* font-weight: 700; */
      font-size: 4.65vh;
      line-height: 5.5vh;
  }
  
  span.h2 {
      /* font-family: 'Inter'; */
      /* font-style: normal; */
      /* font-weight: 400; */
      font-size: 1.86vh;
      line-height: 5vh;
  }
  
  span.h3 {
      /* font-family: 'Inter'; */
      /* font-style: normal; */
      /* font-weight: 400; */
      font-size: .93vh;
      line-height: 2vh;
  }
}



/* >= lg  */
@media (min-width: 992px) {
  span.h1 {
      /* font-family: 'Kanit'; */
      /* font-style: normal; */
      font-weight: 700;
      font-size: calc(min(8vh, 90px));
      line-height: calc(min(9vh, 100px));
  }
  
  span.h2 {
      /* font-family: 'Inter'; */
      /* font-style: normal; */
      /* font-weight: 400; */
      font-size: 3.3vh;
      line-height: 5vh;
  }
  
  span.h3 {
      /* font-family: 'Inter'; */
      /* font-style: normal; */
      /* font-weight: 400; */
      font-size: .93vw;
      line-height: 2vw;
  }
}

/*------------------------------------------------*/


.transi_opacity{
  
  transition:opacity 0.5s; 
}

.transi_ease-in-out {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.ani_fade_in {
  animation: 2s ease-out 0s 1 ani_fadeIn;
  flex: auto;
  /* background-color: red; */
}

.ani_movingdown_arrow {
  /* animation: 1s 1 ani_movingdown_arrow; */
  animation: 5s ease-in-out 1s ani_movingdown_arrow;
  animation-iteration-count: infinite;
}


@keyframes ani_fadeIn {
  0% {
    transform: translateX(0%); opacity: 0;
  }
  100% {
    transform: translateX(0); opacity: 1;
  }
}

@keyframes ani_fadeOut {
  0% {
    transform: translateX(0%); opacity: 1;
  }
  100% {
    transform: translateX(0); opacity: 0;
  }
}


@keyframes ani_beating_burger{
  0% {
      transform: scale(1.15); opacity: 1;
  }
  10% {
      transform: scale(1); opacity: 1;
  }
  20% {
      transform: scale(0.85); opacity: 1;
  }
  30% {
      transform: scale(1.1); opacity: 1;
  }
  100% {
      transform: scale(1); opacity: 1;
  }
}


@keyframes ani_dancing_burger{
  0% {
      transform: scale(1); opacity: 1; transform: rotate(0deg);
  }
  15% {
      transform: scale(1); opacity: 1; transform: rotate(-6deg);
  }
  30% {
      transform: scale(1); opacity: 1; transform: rotate(0deg);
  }
  85% {
  transform: scale(1); opacity: 1; transform: rotate(9deg);
  }
  100% {
      transform: scale(1); opacity: 1; transform: rotate(0deg);
  }
}

@keyframes ani_movingdown_arrow {
  0% {
    transform: translateY(0); opacity: 1;
  }
  30% {
    transform: translateY(0); opacity: 0.5;
  }
  40% {
    transform: translateY(5px); opacity: 1;
  }
  50% {
    transform: translateY(0); opacity: 0.5;
  }
  60% {
    transform: translateY(5px); opacity: 1;
  }
  70% {
    transform: translateY(0); opacity: 0.5;
  }
  100% {
    transform: translateY(0); opacity: 1;
  }
  
}