#pp_content {
    background-color: black;
    font-family: "Kanit";
    color: white;
}

#pp_content_wrapper {
    width: min(90%, 1280px);
    /* height: 100vh; */
}
