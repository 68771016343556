
/* #nav-brand {
    background-color: blue;
} */

#img-logo-gmillion {
    visibility: collapse;
}


#img-logo-burger {
    min-height: 24px;
    height: 18vh;
    max-height: 64px;
    /* position: absolute; */
    left: 0px;
    top: 0px;

    /* background-color: red; */

    /* &.isToggle {
        visibility: hidden;
    } */
}

#img_colp_logo_mobile {
    min-height: 16px;
    max-height: 25vh;
  
}

#img-burger {
    min-height: 24px;
    height: 7vh;
    max-height: 32px;
    /* background-color: red; */
}

.dancing_burger {
    animation: 5s ease-in-out 0.6s ani_dancing_burger;
    animation-iteration-count: infinite;
}


div.dropdown-menu.show {
    background-color: black;
    left: -120px;
    /* background-color: rgba(0,0,0, 0.90); */
}
#nav_colp_mobile_inner {
    max-width: 75vw;
    /* background-color: blue; */
}

#nav_colp_mobile_inner {
    max-width: 60vw;
}

.nav_colp_mobile_item {
    color: #ffffff;
    font-family: 'Kanit';
    font-style: normal;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.4rem;
    /* margin-left: 5px; */
    /* margin-right: 5px; */
    /* align-items: start; */
    max-width: 50vw;
}

.nav_colp_mobile_item:focus
,.nav_colp_mobile_item:hover
,.nav_colp_mobile_item:active {
    color: #FFD95E;
    font-weight: 600;
}

.navlinkitem {
    color: #ffffff;
    font-family: 'Kanit';
    font-style: normal;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
    margin-left: 5px;
    margin-right: 5px;
}

a:link {
    text-decoration: none;
}

a.dropdown-item {
    color: #ffffff;
    font-family: 'Kanit';
    font-style: normal;
    font-size: 26px;
    font-weight: 400;
    line-height: 26px;
}



.dropdown-toggle::after {
    display: none !important; 
}

/* #nav-container {
    background-color: yellow;
} */

#nav_toggle {
    /* background-color: blue; */
    background-image: none !important;
    border: 0px !important;
}

#nav_toggle:focus {
    outline: none;
    box-shadow: none;
    background-image: none !important;
    border: 0px !important;
    /* background-color: blue; */
}

/* #basic-navbar-nav {
    background-color: red;
} */

.nav_colp_scoial_icon {
    width: 32px;
    height: 32px;
    margin: 3px;
    padding: 3px;
    object-fit: contain;
}



/*
xs      0    to 575.98
sm      576  to 767.98
md      768  to 991.98  (portrait tablet)
lg      992  to 1199.98 (landscape tablet)
xl      1200 to 1399.98
*/



#header {
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: rgba(0,0,0, 0.45);
}


#header.navattop {
    background-color: transparent;
}


/*---------- < tablet ------- */
@media (max-width: 991.98px) {
    #img-logo-burger.navattop{
        visibility: hidden;
    }


    
}

/*------ <= mobile landscape --------------*/
@media (max-height: 767.98px) and (orientation: landscape) {
    #img-logo-burger {
        min-height: 12px;
        height: 9vh;
        max-height: 32px !important;
    }

    #img-burger {
        min-height: 24px;
        height: 6vh;
        max-height: 32px;
        /* background-color: red; */
    }    
}

#nav_colp_tablet {
    position: absolute;
    right: 0px;
    background-color: black;

    visibility: hidden;
}

/*------- <= mobile  -----------------*/
@media (max-width: 767.98px)  {

    #img-logo-burger.isToggle {
        visibility: hidden;
        /* background-color: red; */
    }

    #nav-container.show {
        height: 100vh;
        /* background-color: green; */
    }

    #header.isToggle {
        height: 100vh;
        background-color: black !important;
        vertical-align: top;
        align-items: start;
    }
    
    div.dropdown-menu.show {
        position: static;
        width: 100vw;
        height: 100vh;
        /* top: 0px; */
        /* left: 0px; */
        background-color: black;
        margin: 0px;
    }
}

/*-------- >= tablet ---------------*/
@media (min-width: 768px) {
    #img-logo-burger.navattop{
        visibility: hidden;
        
    }
    

    #header.isToggle {
        /* height: 100vh; */
        /* background-color: black !important; */
        vertical-align: top;
        align-items: start;
    }

    

    .navlinkitem {
        /* align-items: start; */
        text-align: start;
        /* background-color: red; */
        line-height: 34px;
    }

    .navlinkitem:focus
    ,.navlinkitem:hover
    ,.navlinkitem:active {
        color: #FFD95E;
        font-weight: 600;
    }
}

@media (min-width: 768px) and (max-width: 991.98px)  {
    #nav_colp_tablet {
        visibility: visible !important;
    }
}

@media (orientation: landscape) and (max-width: 991.98px)  {
    #basic-navbar-nav{
        /* position: relative; */
        background-color: gray !important;
    }

    #nav_colp_mobile {
        position: absolute;
        visibility: collapse;
        /* background-color: red !important; */
    }

    #header.isToggle {
        /* height: 100vh; */
        background-color: transparent !important;
        vertical-align: top;
        align-items: start;
    }

    #nav_colp_tablet {
        width: auto;
        visibility: visible !important;

        /* background-color: red; */
        
    }


    
}


/*------- > tablet landscape and pc ------------------*/
@media (min-width: 992px) {
    #img-logo-burger.navattop{
        visibility: visible;
    }

    #img-logo-burger.isToggle {
        visibility: visible !important;
    }

    #nav_colp_tablet {
        visibility: hidden;
    }

    /* #header.isToggle {
        background-color: rgba(0,0,0, 0.45);
        
    } */

    div.dropdown-menu.show {
        /* top: 0px; */
        /* left: 0px; */
        background-color: black;
        margin: 0px;
    }
}