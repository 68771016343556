#footer {
    background-color: black;
    color: white;
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
}

#logo_container {
    max-width: 80vw;
}

#l_sonew, #l_gmillion{
    max-width: 50%;
    padding: 0px;
    margin: 0px;

    /* background-color: red; */
}

#img_sonew, #img_gmillion {
    max-height: 64px;
}

#div_hr {
    width: 80vw;
}

.ft_link {
    color: white;
}

