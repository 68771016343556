@import "~react-image-gallery/styles/css/image-gallery.css";

.homepart {
    position: relative;
    /* overflow: hidden !important; */
    margin: 0px !important;
    padding: 0px !important;
    /* background-color:  rgba(255,0,0, 0.30); */
}

@media (orientation: landscape){
    .homepart {
        min-height: 90vh;
        max-width: 100vw;
    }
    .homepart.superwidth{
        /* max-width: 50vw; */
        /* margin-left: 100px !important; */
        background-color: rgba(255,0,0, 0.30);;
    }
}
@media (orientation: portrait){
    .homepart {
        min-height: 35vh;
        max-width: 100vw;
    }
    

    #home2
    ,#home3 {
        min-height: 55vh !important;
    }
}

#home_header{
    z-index: 99;
    background-color: #242223;
    /* background-color: red; */
    height: 32px;
    /* height: 18vh;
    max-height: 64px; */

}



/*------------- home part style 3 image over image dif zoom ratio ------*/

.home_s3_bg_container {
    position: relative;
    max-width: 1568px;
    max-height: 891px;
    /* background-color:  rgba(0,0,255, 0.30); */
}

.home_s3_bg_img {
    position: relative;
    max-width: 1568px;
    max-height: 891px;
    z-index: 0;
    object-fit: contain;
}

.home_s3_content_over_flexx {
    position: absolute;
    height: 100%;
    /* background-color:  rgba(0,255,0, 0.30); */
}

.home_s3_content_over_flexy {
    position: relative;
}

.home_s3_img_over {
    z-index: 1;
    object-fit: contain;
    /* background-color: red; */
}

@media (orientation: landscape){
    .home_s3_bg_container {
        width: 81.67%;
    }
    .home_s3_bg_img {
        width: 81.67vw;
    }
    .home_s3_content_over_flexx {
        width: 100%;
    }
    .home_s3_content_over_flexy {
        max-height: 100vh;
        max-width: 80vw;
    }
}

@media  (orientation: portrait){
    .home_s3_bg_container {
        width: 94.62%;
    }
    .home_s3_bg_img {
        width: 94.62vw;
    }
    .home_s3_content_over_flexx {
        width: 100%;
    }
    .home_s3_content_over_flexy {
        max-height: 80vh;
        max-width: 80vw;
    }
}


/* --------------------------------------------------------*/



/*---------------- Home1 ----------------*/
/*----------------- Responsive object smaller than md -------------*/

#home1_div_all01{
    position: relative;
    overflow: hidden;
    
    /* width: calc(100% + 7vw); */
    /* background-color: rgba(255,0,0,0.5); */
    /* top: -10vh; */
    /* width: calc(auto + 7vw); */
    /* right: -7vw; */
}

@media (min-width: 1921px) {
    #home1_div_all01{
        right: -10vw;
    }
}

#all01 {
    position: relative;
    top: -8vh;
    /* right: -7vw; */
    /* object-position: 7vw 100% !important; */
    z-index: -9;
    /* width: auto; */
    height: calc(100vh - 32px + 10vh) !important;
    object-fit: cover;
    filter: brightness(100%);
    /* filter: linear-gradient(to bottom, brightness(100%), brightness(0%)); */
    /* opacity: 0.5; */
    /* color-interpolation-filters: linearRGB; */
    /* backdrop-filter: opacity(60%); */
    
    /* background-color: blue; */
    /* background-image: linear-gradient(to bottom, rgba(255,0,0,0), rgba(255,0,0,1));  */
}

#home1_div_all01 {
    
}

#home1_content {
    height: calc(100vh - 32px);
    width: 60%;
    margin-left: 10%;
    margin-right: 30%;
    /* background-color: rgba(255,255,255,0.5); */
}

#home1_content_inner{
    min-height: 90%;
    /* background-color: rgba(0,255,0,0.5); */

}

#home1_logo_ilb {
    max-height: 25vh;
    max-width: 70%;
    object-fit: contain;

    /* background-color: red; */
}

#home1_t1 {
    text-align: start;
}

#home1_dw_container {
    min-height: 32px;
    max-height: 128px;
    height: calc(min(20vh, 20vw));

    /* margin-left: 0%; */

    /* background-color: rgba(0,0,255,0.5); */
}

#l-home1-arrow-down {
    visibility: collapse;
}

#img-home1-arrow-down {
    min-width: 16px;
    max-width: 32px;
    
}

#home1_dw_alicia {
    min-width: 32px;
    min-height: 32px;
    max-width: 128px;
    max-height: 128px;
    height: 100%;
    object-fit: contain;

    
}

#home1_dw_link{
    position: relative;
    height: 100%;
    
}

#l_dw_google{
    height: 45.40% !important;
}

#l_dw_ios {
    height: 51.60% !important;
}

.img_dw_link {
    height: 100% !important;
}

#l_play_lg {
    height: 50% !important;
}

#img_dw_play {
    height: 100% !important;
}

#home1_t2 {
    text-align: start;
}

@media (orientation: portrait) {  
    #home1_div_all01 {
        position: relative;
        /* margin: 0px !important; */
        /* padding: 0px !important; */
        /* height: calc(110vh - 32px ) !important; */
        object-position: 30% 100% !important;
        height: calc(102vh - 32px);
    }

    #all01 {
        position: relative;
        right: 0px;
        top: 0px;
        bottom: 0px;
        /* height: calc(vh - 32px ) !important; */
        height: 100% !important;
        /* object-position: 38% 100% !important; */
        filter: brightness(50%);

        /* background-color: red !important; */
    }
    
    #home1_content {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    #home1_logo_ilb {
        /* background-color: red; */
    }

    #home1_t1 {
        text-align: center;
        visibility: hidden;
    }

    #l-home1-arrow-down {
        visibility: visible;
    }
    
    #home1_t2 {
        text-align: center;
    }
}

/*---------------- End Home1 ----------------*/

/*------- image gal decor-------*/


#p4_img1 {
    position: absolute;
    max-width: 190px;
    max-height: 151px;
    object-fit: contain;
    z-index: 99;
    
}

#p4_img2 {
    position: absolute;
    max-width: 223px;
    max-height: 157px;
    object-fit: contain;
    z-index: 99;

}


#p5_img1 {
    position: absolute;
    max-width: 397px;
    max-height: 192px;
    object-fit: contain;
    z-index: 99;
    
}

#p5_img2 {
    position: absolute;
    max-width: 260px;
    max-height: 233px;
    object-fit: contain;
    z-index: 99;

}



@media (orientation: portrait) {
    #p4_img1 {
        top: -3vw;
        height: 9vw;
        right: -1vw;
    }
    #p4_img2 {
        top: calc((min(100vw, 1280px) - 32px - 32px) * 0.5625 - 4vw) ;
        height: 9vw;
        left: -2vw;
    }
    #p5_img1 {
        top: -3vw;
        height: 10vw;
        right: 5vw;
    }
    #p5_img2 {
        top: calc((min(100vw, 1280px) - 32px - 32px) * 0.5625 - 4vw) ;
        height: 10vw;
        left: 4vw;
    }

    .gal_title {
        height: 12vw;
        max-width: 80vw;
        object-fit: contain;
    }

    .gal_text_container {
        position: absolute;
        width: 100%;
        z-index: 99;
        bottom: calc(max(12.5vw, 32px) + 6vw);
        /* bottom: 74px; */
        /* bottom: 0px; */
        /* background-color: red; */
    }

    .gal_text_t1 {
        /* position: absolute; */
        height: calc(((min(100vw, 1280px) - 32px - 32px) /12));
        max-width: 80vw;
        object-fit:contain;
        /* z-index: 99; */
        /* bottom: 152px;
        left: -35px; */
        /* background-color: blue; */
    }
}

@media (orientation: landscape) {
    #p4_img1 {
        top: -3vh;
        height: 9vh;
        right: -1vh;
        /* background-color: red; */
    }
    #p4_img2 {
        /* top: calc((min(100vw, 1280px) - 32px - 32px) * 0.5625 - 4vw) ; */
        top: calc( min(70vh, 720px) - 5vh);
        height: 9vh;
        left: 13vh;
        /* background-color: red; */
    }
    #p5_img1 {
        top: -6vh;
        height: 12vh;  
        right: 3vh;
    }
    #p5_img2 {
        bottom: -3vh;
        height: 12vh;
        left: 24vh;
    }

    .gal_title_container {
        position: relative;
        z-index: 99;
        /* top: -3vw; */
        align-items: start;
        align-content: start;
        text-align: start;
        padding-left: calc((min(100vw, 1920px) - min(75vw, 1485px))/2);
        /* background-color: red; */

    }
    .gal_title {
        /* position: absolute; */
        height: calc(max(4vh, min(4vw, 76px)));
        max-width: 50vw;
        object-fit: contain;
        z-index: 99;
        left: 0px;
        top: -3vw;

        /* background-color: blue; */
    }

    .gal_text_container {
        position: absolute;
        width: 100%;
        z-index: 99;
        align-items: end;
        align-content: end;
        text-align: end;
        padding-right: 2vw;
        bottom: calc(-1 * min(70vh, 720px)/20);
        /* background-color: red; */
    }

    .gal_text_t1 {
        /* position: absolute; */
        max-height: max(4vh, 4vw);
        /* max-width: 30vw; */
        object-fit:contain;
        text-align: end;
        /* background-color: blue; */
    }
}
