
/*
xs      0    to 575.98
sm      576  to 767.98
md      768  to 991.98  (portrait tablet)
lg      992  to 1199.98 (landscape tablet)
xl      1200 to 1399.98
*/


#righter {
    position: fixed;
    z-index: 999;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    pointer-events: none;

    /* background-color: rgba(255,255,255,0.4); */
}

#rt_social_container_outer {
    position: absolute;
    z-index: 99;
    right: max(calc((100vw - 1920px)/2),0px);
    width: auto;
    height: 100vh;
    pointer-events:fill;

    visibility: hidden;

    /* background-color: rgba(255,0,0,0.4); */
}

#rt_social_container_inner {
    /* background-color: rgba(0,255,0,0.4); */
}

.l_social {
    margin-right: 1rem !important;
    &:hover {
        margin-right: 2rem !important;
    }
}

.hideattop {
    visibility: hidden !important;
    opacity: 0;
}

.showattop {
    visibility: visible !important;
}

#rt_dw_container_outer {
    z-index: 98;
    position: absolute;
    bottom: 0px;
    right: max(calc((100vw - 1920px)/2),0px);
    pointer-events:fill;

    visibility: hidden;
    opacity: 0;

    /* background-color: rgba(0,0,255,0.4); */
}

@media (orientation: landscape)
and (min-width: 992px){ 
    #rt_dw_container_outer{
        visibility: visible;
        opacity: 1 !important;
    }

    #rt_social_container_outer{
        visibility: visible !important;
    }
}

#rt_dw_container_inner {
    
    /* background-color: rgba(125,125,0,0.5); */

    &.isAtbottom{
        position: static !important;
        /* background-color: rgba(255,125,0,1); */
    }
}

.isAtbottom{
    /* position:relative !important; */
    
    /* background-color: rgba(255,125,0,1); */
}


#rt_alicia {
    object-fit: cover;
    max-width: 128px;
    max-height: 128px;

    /* background-color: rgba(125,125,125,0.5); */
}

#rt_a_google {
    /* background-color: rgba(0,125,125,0.5); */
}

#rt_img_google, #rt_img_ios {
    max-width: 128px;
}

@media (max-width: 1199.98px) {
    #rt_alicia {
        max-width: 96px;
        max-height: 96px;
    }

    #rt_img_google, #rt_img_ios {
        max-width: 96px;
    }
}

#rt_BottomSpot{
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 0px;
    height: 0px;
    /* background-color: red; */
}