
#cs_header{
    background-color: #242223;
    /* background-color: red; */
    height: 32px;
    /* width: 100vw; */
    /* height: 18vh;
    max-height: 64px; */

}

#cs_img_gmillion_logo{
    height: 100%;
    left: 0px;
}

#cs_img_all01_container {
    overflow: hidden;
    height: calc(100vh - 32px);
}

#cs_img_all01 {
    z-index: 0;
    /* width: auto; */
    /* height: calc(102vh - 32px); */
    height: 103%;
    right: 0px;
    object-fit: cover;
    object-position: 48% 100% !important;
    filter: brightness(100%) !important;
    
    /* background-color: blue; */
}

#cs_content {
    z-index: 3;
    left: 0px;
    height: calc(100vh - 32px);
    /* width: 100vw; */
    /* background-color: beige; */

}

#cs_content_inner {
    position: relative;
    /* background-color: rgba(0,0,255,0.3) !important; */

    height: 80vh;
    width: 50vw;
}

#cs_logo_container {
    position: relative;
    height: 50%;
    min-width: 50%;
    width: 75%;
    max-width: 80%;
    
    /* background-color: rgba(255,0,0, 0.30); */
    
}

#cs_img_logo {
    max-height: 100%;
    /* background-color: rgba(0,255,0,0.3); */
}

#cs_content_container {
    height: 50%;

    /* background-color: blue; */
}

#cs_text_container {
    font-family: 'Kanit';
    font-weight: 700 !important;
    font-size: 9vh;
    line-height: 9.5vh;
    margin-bottom: 2vh;

    /* overflow: hidden; */
    /* background-color: rgba(255,0,0, 0.30); */
}

#cs_coming_soon_container {
    margin-bottom: 2vh;
    /* background-color: yellow; */
}

#cs_img_coming_soon {
    max-height: 13.5vh;
    max-width: 90%;
    
}

#cs_div_white_bg {
    width: 0px;
    height: 0px;
    opacity: 0;
    visibility: collapse;
}


@media  (orientation: portrait){

    #cs_div_white_bg {
        z-index: 1;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100%;
        opacity: 1;
        visibility: visible;
        background: linear-gradient(180deg, rgba(255,255,255,0) 33%, rgba(255,255,255,1) 100%);
    }
    
    #cs_content_inner {
        margin-top: 45vh;
        height: 50vh;
        width: 100vw;

        /* backdrop-filter: linear-gradient(180deg, rgba(255,255,255,0) 33%, rgba(255,255,255,1) 100%); */
    }

    #cs_logo_container {
        height: 33%;
    }
    #cs_content_container {
        height: 66%;
    }

    #cs_text_container {
        /* height: 50%; */
        font-family: 'Kanit';
        font-weight: 700 !important;
        font-size: 5vh;
        line-height: 5vh;
        margin-bottom: 0vh;
    }

    #cs_coming_soon_container {
        height: 50%;
    }

    #cs_img_coming_soon {
        width: 75%;
    }
}

